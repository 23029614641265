/* eslint-disable react/no-unused-state */
// ^^state used in child classes via inheritance

import React from 'react';
import PropTypes from 'prop-types';
import commonStyle from './style.css';

// used for methods that are exactly the same in StudentLanding and StudentAssignment
export default class StudentCommon extends React.Component {
    static propTypes = {
        onComplete: PropTypes.func,
    };

    static defaultProps = {
        onComplete: null,
    };

    state = {
        assessmentHeader: '',
        complete: false,
        error: false,
        language: null,
        loading: true,
        name: '',
        session: null,
        student: null,
    };

    componentDidMount() {
        this.getStudentInfo();
        document.body.classList.add(commonStyle.blueGradient);
    }

    componentWillUnmount() {
        document.body.classList.remove(commonStyle.blueGradient);
    }

    showError = () => (
        <div className={commonStyle.wrapper}>
            <div className={commonStyle.question}>
                Error retrieving student information
                <br />
                <br />
                Please contact support
            </div>
        </div>
    );

    showSelHeader = () => (
        <span
            style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'left',
                pointerEvents: 'none',
                position: 'absolute',
                color: '#666666',
                top: '19px',
                left: '150px',
                zIndex: 10000,
            }}
        >
            {this.state.assessmentHeader}
        </span>
    );

    showNoSession = () => (
        <div className={commonStyle.wrapper}>
            <div className={commonStyle.question}>
                No student data collection is active at this time
                <br />
            </div>
        </div>
    );
}
